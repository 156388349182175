<template>
    <div>
        <vue-title title="Добавить транспорт | KEL.KZ"></vue-title>
        <Header :routes="headerRoutes.defaultsoutes"/>
        <div class="container py-4">
            <div class="long-title text-center mb-3">
                <h1 class="page-title">Добавить транспорт</h1>
            </div>
            <div class="row">
                <div class="col-12">
                    <div id="form-container">
                        <div v-if="okMessage" class="alert alert-success mb-3" role="alert">
                            {{ okMessage }}
                        </div>
                        <div v-if="errorMessage" class="alert alert-danger mb-3" role="alert">
                            <strong>Внимание!</strong> {{ errorMessage }}
                        </div>
                        <form class="car-form" @submit.prevent="onSubmit">
                            <DxForm
                                    id="form"
                                    :col-count="1"
                                    :form-data="formData"
                                    :read-only="false"
                                    :show-colon-after-label="true"
                                    :show-validation-summary="true"
                                    :label-location="labelLocation"
                                    :disabled="isLoading"
                                    @field-data-changed="formFieldDataChanged"
                            >
                                <DxGroupItem
                                        :colCount="8"
                                        cssClass="mb-3"
                                >
                                    <DxColCountByScreen :xs="1" :sm="1"/>
                                    <DxSimpleItem
                                            data-field="number"
                                            :colSpan="1"
                                    >
                                        <DxLabel text="Номер авто"/>
                                        <DxRequiredRule message="Укажите номер авто"/>
                                    </DxSimpleItem>
                                    <DxSimpleItem
                                            data-field="vin"
                                            :editor-options="{maxLength: 17}"
                                            :colSpan="2"
                                    >
                                        <DxStringLengthRule
                                                :min="17"
                                                :max="17"
                                                message="VIN-номер должен содержать 17 символов"
                                                type="stringLength"
                                                :ignore-empty-value="true"
                                        />
                                        <DxLabel text="VIN-номер"/>
                                    </DxSimpleItem>
                                    <DxSimpleItem
                                            data-field="brandId"
                                            :editor-options="{
                                                    dataSource: storeCarBrands,
                                                    displayExpr: 'brandName',
                                                    valueExpr: 'brandId',
                                                    value: formData.brandId,
                                                    searchEnabled: true,
                                                    showDataBeforeSearch: true,
                                                    showClearButton: true,
                                                    onValueChanged: handleBrandValueChange
                                                }"
                                            editor-type="dxSelectBox"
                                            :colSpan="2"
                                    >
                                        <DxLabel text="Бренд"/>
                                        <DxRequiredRule message="Укажите Бренд"/>
                                    </DxSimpleItem>
                                    <DxSimpleItem
                                            data-field="model"
                                            :colSpan="3"
                                    >
                                        <DxLabel text="Модель"/>
                                        <DxRequiredRule message="Укажите Модель"/>
                                    </DxSimpleItem>
                                </DxGroupItem>
                                <DxGroupItem
                                        caption="Характеристики транспортного средства"
                                        :colCount="2"
                                >
                                    <DxSimpleItem
                                            :colSpan="1"
                                    >
                                        <DxLabel text="Фото транспортного средства" />
                                        <template #default>
                                            <div>
                                                <div class="row text-center text-md-left">
                                                    <div class="col-12 mb-3">
                                                        <img :src="carFileImageSource" class="img-thumbnail rounded" style="min-height: 200px; min-width: 200px; max-height: 200px">
                                                    </div>
                                                    <div class="col-12">
                                                        <DxButton
                                                                id="carFile-add-external"
                                                                text="Выбрать файл"
                                                                type="default"
                                                                styling-mode="contained"
                                                                :element-attr="{class: 'mr-2'}"
                                                        />
                                                        <DxButton
                                                                id="carFile-del-external"
                                                                text="Удалить"
                                                                type="danger"
                                                                styling-mode="contained"
                                                                @click="onDelFile('carFile')"
                                                                :disabled="carFileImageSource == ''"
                                                        />
                                                    </div>
                                                </div>
                                                <DxFileUploader
                                                        id="carFile"
                                                        dialog-trigger="#carFile-add-external"
                                                        :multiple="false"
                                                        upload-mode="useForm"
                                                        :visible="false"
                                                        accept="image/*"
                                                        @value-changed="carFileValueChanged"
                                                />
                                            </div>
                                        </template>
                                    </DxSimpleItem>
                                    <DxGroupItem
                                            :colCount="1"
                                            :colSpan="1"
                                    >
                                        <DxSimpleItem
                                                :editor-options="{
                                                displayExpr: 'name',
                                                valueExpr: 'value',
                                                searchEnabled: false,
                                                items: specifications,
                                                showClearButton: true,
                                                acceptCustomValue: false,
                                                readOnly: true
                                            }"
                                                data-field="specification"
                                                editor-type="dxSelectBox"
                                        >
                                            <DxLabel text="Спецификация"/>
                                        </DxSimpleItem>
                                        <DxSimpleItem
                                                :editor-options="{searchEnabled: true, dataSource: storeRollingStockTypes, displayExpr: 'rollingStockTypeName', valueExpr: 'rollingStockTypeId', showClearButton: true}"
                                                data-field="rollingStockTypeId"
                                                editor-type="dxSelectBox"
                                        >
                                            <DxRequiredRule v-if="formData.specification == 'spcTrailer'" message="Укажите кузов"/>
                                            <DxLabel text="Кузов"/>
                                        </DxSimpleItem>
                                        <DxSimpleItem
                                                :editor-options="{searchEnabled: true, dataSource: storeRollingStockUnitTypes, displayExpr: displayExprRollingStockUnitTypes, valueExpr: 'rollingStockUnitTypeId', showClearButton: true}"
                                                data-field="rollingStockUnitTypeId"
                                                editor-type="dxSelectBox"
                                        >
                                            <DxCustomRule message="Укажите грузоподъемность" type="custom" :reevaluate="true" :validation-callback="validateRollingStockUnitType" />
                                            <DxLabel text="Грузоподъемность"/>
                                        </DxSimpleItem>
                                    </DxGroupItem>
                                </DxGroupItem>
                                <DxGroupItem
                                        caption="Свидетельства о регистрации транспортного средства"
                                        :colCount="3"
                                >
                                    <DxSimpleItem>
                                        <DxLabel text="Фото передней части"/>
                                        <template #default>
                                            <div>
                                                <div class="row text-center text-md-left">
                                                    <div class="col-12 mb-3">
                                                        <img :src="techicalPassportFileFrontImageSource" class="img-thumbnail rounded" style="min-height: 200px; min-width: 200px; max-height: 200px">
                                                    </div>
                                                    <div class="col-12">
                                                        <DxButton
                                                                id="techicalPassportFileFront-add-external"
                                                                text="Выбрать"
                                                                type="default"
                                                                styling-mode="contained"
                                                                :element-attr="{class: 'mr-2'}"
                                                        />
                                                        <DxButton
                                                                id="techicalPassportFileFront-del-external"
                                                                text="Удалить"
                                                                type="danger"
                                                                styling-mode="contained"
                                                                @click="onDelFile('techicalPassportFileFront')"
                                                                :disabled="techicalPassportFileFrontImageSource == ''"
                                                        />
                                                    </div>
                                                </div>
                                                <DxFileUploader
                                                        id="techicalPassportFileFront"
                                                        dialog-trigger="#techicalPassportFileFront-add-external"
                                                        :multiple="false"
                                                        upload-mode="useForm"
                                                        :visible="false"
                                                        accept="image/*"
                                                        @value-changed="techicalPassportFileFrontValueChanged"
                                                />
                                            </div>
                                        </template>
                                    </DxSimpleItem>
                                    <DxSimpleItem>
                                        <DxLabel text="Фото задней части"/>
                                        <template #default>
                                            <div>
                                                <div class="row text-center text-md-left">
                                                    <div class="col-12 mb-3">
                                                        <img :src="techicalPassportFileBackImageSource" class="img-thumbnail rounded" style="min-height: 200px; min-width: 200px; max-height: 200px">
                                                    </div>
                                                    <div class="col-12">
                                                        <DxButton
                                                                id="techicalPassportFileBack-add-external"
                                                                text="Выбрать"
                                                                type="default"
                                                                styling-mode="contained"
                                                                :element-attr="{class: 'mr-2'}"
                                                        />
                                                        <DxButton
                                                                id="techicalPassportFileBack-del-external"
                                                                text="Удалить"
                                                                type="danger"
                                                                styling-mode="contained"
                                                                @click="onDelFile('techicalPassportFileBack')"
                                                                :disabled="techicalPassportFileBackImageSource == ''"
                                                        />
                                                    </div>
                                                </div>
                                                <DxFileUploader
                                                        id="techicalPassportFileBack"
                                                        dialog-trigger="#techicalPassportFileBack-add-external"
                                                        :multiple="false"
                                                        upload-mode="useForm"
                                                        :visible="false"
                                                        accept="image/*"
                                                        @value-changed="techicalPassportFileBackValueChanged"
                                                />
                                            </div>
                                        </template>
                                    </DxSimpleItem>
                                    <DxGroupItem
                                            :colCount="1"
                                            :colSpan="1"
                                    >
                                        <DxSimpleItem
                                                data-field="techicalPassport"
                                        >
                                            <DxLabel text="Номер"/>
                                        </DxSimpleItem>
                                        <DxSimpleItem
                                                data-field="issuedDate"
                                                editor-type="dxDateBox"
                                        >
                                            <DxLabel text="Дата выдачи"/>
                                        </DxSimpleItem>
                                    </DxGroupItem>
                                </DxGroupItem>
                                <DxGroupItem
                                        :colCount="1"
                                >
                                    <DxGroupItem
                                            caption="Технический осмотр"
                                            :colCount="3"
                                    >
                                        <DxSimpleItem :colSpan="2">
                                            <DxLabel text="Фото талона прохождения технического осмотра"/>
                                            <template #default>
                                                <div>
                                                    <div class="row text-center text-md-left">
                                                        <div class="col-12 mb-3">
                                                            <img :src="techInspectionFileImageSource" class="img-thumbnail rounded" style="min-height: 200px; min-width: 200px; max-height: 200px">
                                                        </div>
                                                        <div class="col-12">
                                                            <DxButton
                                                                    id="techInspectionFile-add-external"
                                                                    text="Выбрать"
                                                                    type="default"
                                                                    styling-mode="contained"
                                                                    :element-attr="{class: 'mr-2'}"
                                                            />
                                                            <DxButton
                                                                    id="techInspectionFile-del-external"
                                                                    text="Удалить"
                                                                    type="danger"
                                                                    styling-mode="contained"
                                                                    @click="onDelFile('techInspectionFile')"
                                                                    :disabled="techInspectionFileImageSource == ''"
                                                            />
                                                        </div>
                                                    </div>
                                                    <DxFileUploader
                                                            id="techInspectionFile"
                                                            dialog-trigger="#techInspectionFile-add-external"
                                                            :multiple="false"
                                                            upload-mode="useForm"
                                                            :visible="false"
                                                            accept="image/*"
                                                            @value-changed="techInspectionFileValueChanged"
                                                    />
                                                </div>
                                            </template>
                                        </DxSimpleItem>
                                        <DxSimpleItem
                                                data-field="techInspection"
                                                editor-type="dxDateBox"
                                        >
                                            <DxLabel text="Дата прохождения"/>
                                        </DxSimpleItem>
                                    </DxGroupItem>
                                    <DxGroupItem
                                            caption="Страховой полис"
                                            :colCount="3"
                                    >
                                        <DxSimpleItem  :colSpan="2">
                                            <DxLabel text="Фото страхового полюса"/>
                                            <template #default>
                                                <div>
                                                    <div class="row text-center text-md-left">
                                                        <div class="col-12 mb-3">
                                                            <img :src="insuranceFileImageSource" class="img-thumbnail rounded" style="min-height: 200px; min-width: 200px; max-height: 200px">
                                                        </div>
                                                        <div class="col-12">
                                                            <DxButton
                                                                    id="insuranceFile-add-external"
                                                                    text="Выбрать"
                                                                    type="default"
                                                                    styling-mode="contained"
                                                                    :element-attr="{class: 'mr-2'}"
                                                            />
                                                            <DxButton
                                                                    id="insuranceFile-del-external"
                                                                    text="Удалить"
                                                                    type="danger"
                                                                    styling-mode="contained"
                                                                    @click="onDelFile('insuranceFile')"
                                                                    :disabled="insuranceFileImageSource == ''"
                                                            />
                                                        </div>
                                                    </div>
                                                    <DxFileUploader
                                                            id="insuranceFile"
                                                            dialog-trigger="#insuranceFile-add-external"
                                                            :multiple="false"
                                                            upload-mode="useForm"
                                                            :visible="false"
                                                            accept="image/*"
                                                            @value-changed="insuranceFileValueChanged"
                                                    />
                                                </div>
                                            </template>
                                        </DxSimpleItem>
                                        <DxSimpleItem
                                                data-field="finisedDateInsurance"
                                                editor-type="dxDateBox"
                                        >
                                            <DxLabel text="Дата окончания"/>
                                        </DxSimpleItem>
                                    </DxGroupItem>
                                </DxGroupItem>
                                <DxGroupItem
                                        :colCount="1"
                                >
                                    <DxButtonItem
                                            :button-options="buttonOptions"
                                    />
                                </DxGroupItem>
                            </DxForm>
                        </form>
                    </div>
                </div>
            </div>
        </div>
        <Footer/>
    </div>
</template>

<script>
    import headerRoutes from './header-routes';
    import {mapGetters} from 'vuex';
    import {httpClient} from '../../shared/services';
    import {Header, Footer} from '../../shared/components';
    import CustomStore from 'devextreme/data/custom_store';
    import { DxFileUploader } from 'devextreme-vue/file-uploader';
    import DxButton from 'devextreme-vue/button';
    import {
        DxForm,
        DxGroupItem,
        DxButtonItem,
        DxColCountByScreen,
        DxSimpleItem,
        DxLabel,
        DxRequiredRule,
        DxStringLengthRule,
        DxCustomRule
    } from 'devextreme-vue/form';

    export default {
        name: 'CarrierCarAdd',
        components: {
            Header,
            Footer,
            DxForm,
            DxGroupItem,
            DxButtonItem,
            DxColCountByScreen,
            DxSimpleItem,
            DxLabel,
            DxRequiredRule,
            // eslint-disable-next-line
            DxFileUploader,
            DxStringLengthRule,
            DxButton,
            DxCustomRule
        },
        data() {
            return {
                headerRoutes,
                formData: {
                    specification: this.$route.params.motor == 'no-motor' ? 'spcTrailer' : 'spcTractor'
                },
                labelLocation: 'top',
                okMessage: "",
                errorMessage: "",
                motor: this.$route.params.motor,
                specification: this.$route.params.motor == 'no-motor' ? 'spcTrailer' : 'spcTractor',
                specifications: [
                    {
                        name: 'Тягач',
                        value: 'spcTractor'
                    },
                    {
                        name: 'Фургон',
                        value: 'spcCar'
                    },
                    {
                        name: 'Прицеп',
                        value: 'spcTrailer'
                    }
                ],
                buttonOptions: {
                    text: 'Сохранить',
                    type: 'default',
                    useSubmitBehavior: true
                },
                storeRollingStockTypes: new CustomStore({
                    key: 'rollingStockTypeId',
                    byKey: function () {
                        return {};
                    },
                    load: (loadOptions) => {
                        let searchValue = loadOptions.searchValue != null ? loadOptions.searchValue : '';

                        let filter = searchValue != '' ? `?filter={"rollingStockTypeName":{"op":"con","value":"${searchValue}"}}` : '';

                        return httpClient.get(`v2/Catalog/RollingStockTypes${filter}`)
                            .then((data) => {
                                return {
                                    key: 'rollingStockTypeId',
                                    data: data.data.items,
                                    totalCount: data.totals,
                                    summary: data.totals,
                                    groupCount: data.count,
                                };
                            })
                            .catch(() => {
                                throw 'Data Loading Error';
                            });
                    }
                }),
                storeRollingStockUnitTypes: [],
                countSaveFiles: 0,
                rollingStockUnitTypeId: null,
                carFileImageSource: '',
                techicalPassportFileFrontImageSource: '',
                techicalPassportFileBackImageSource: '',
                techInspectionFileImageSource: '',
                insuranceFileImageSource: '',
                spcCarRequired: false,
                storeCarBrands: new CustomStore({
                    key: 'brandId',
                    byKey: function () {
                        return {}
                    },
                    load: (loadOptions) => {
                        //console.log(loadOptions);

                        let searchValue = loadOptions.searchValue != null ? loadOptions.searchValue : '';

                        let url = `v2/Catalog/carBrands?filter={"brandName":{"op":"con","value":"${searchValue}"}}`;

                        if(searchValue == '') {
                            url = `v2/Catalog/carBrands`;
                        }

                        return httpClient.get(url)
                            .then((data) => {
                                return {
                                    key: 'brandId',
                                    data: data.data.items,
                                    totalCount: data.totals,
                                    summary: data.totals,
                                    groupCount: data.count,
                                    userData: data.data,
                                };
                            })
                            .catch(() => {
                                throw 'Data Loading Error';
                            });
                    }
                }),
            }
        },
        mounted() {
            this.$store.dispatch('loading', false);
        },
        methods: {
            onSubmit: async function () {
                this.okMessage = "";
                this.errorMessage = "";

                this.$store.dispatch('loading', true).then(() => {
                    let tokenId = this.Agent.tokenId;

                    let {
                        number,
                        brandId,
                        model,
                        vin,
                        techicalPassport,
                        issuedDate,
                        techInspection,
                        finisedDateInsurance,
                        specification,
                        rollingStockTypeId,
                        rollingStockUnitTypeId,
                        techicalPassportFileFront,
                        techicalPassportFileBack,
                        techInspectionFile,
                        insuranceFile,
                        carFile
                    } = this.formData;

                    let isValidVIN = false;

                    if(vin != "" && vin != undefined && vin != null) {
                        if(this.validateVin(vin)) {
                            isValidVIN = true;
                        } else {
                            isValidVIN = confirm("VIN-номер не прошел проверку. Номер указан верно?");
                        }
                    } else {
                        isValidVIN = true;
                    }

                    if(isValidVIN) {
                        if(techicalPassportFileFront) {
                            this.countSaveFiles = this.countSaveFiles+1;
                        }

                        if(techicalPassportFileBack) {
                            this.countSaveFiles = this.countSaveFiles+1;
                        }

                        if(techInspectionFile) {
                            this.countSaveFiles = this.countSaveFiles+1;
                        }

                        if(insuranceFile) {
                            this.countSaveFiles = this.countSaveFiles+1;
                        }

                        if(carFile) {
                            this.countSaveFiles = this.countSaveFiles+1;
                        }

                        return httpClient
                            .post(`v2/Carrier/Cars?tokenId=${tokenId}`, {
                                number: number,
                                brandId: brandId,
                                model: model,
                                vin: vin,
                                techicalPassport: techicalPassport,
                                issuedDate: issuedDate,
                                techInspection: techInspection,
                                finisedDateInsurance: finisedDateInsurance,
                                specification: specification,
                                rollingStockTypeId: rollingStockTypeId,
                                rollingStockUnitTypeId: rollingStockUnitTypeId
                            })
                            .then((response) => {
                                if(this.countSaveFiles > 0) {
                                    if(techicalPassportFileFront) {
                                        this.saveBase64(techicalPassportFileFront[0], response.data.carId, 'СРТС-П', tokenId)
                                    }

                                    if(techicalPassportFileBack) {
                                        this.saveBase64(techicalPassportFileBack[0], response.data.carId, 'СРТС-З', tokenId)
                                    }

                                    if(techInspectionFile) {
                                        this.saveBase64(techInspectionFile[0], response.data.carId, 'ТПТО', tokenId)
                                    }

                                    if(insuranceFile) {
                                        this.saveBase64(insuranceFile[0], response.data.carId, 'СтП', tokenId)
                                    }

                                    if(carFile) {
                                        this.saveBase64(carFile[0], response.data.carId, 'ФОТО-ТС', tokenId)
                                    }
                                } else {
                                    this.$router.push({name: 'CarrierCars'});
                                }
                            }).catch(this.handleError);
                    } else {
                        this.$store.dispatch('loading', false);
                    }
                }).catch(this.handleError);
            },
            handleError: function (error) {
                this.errorMessage = "Ошибка при сохранении данных. Проверьте указанные вами данные.";
                this.$store.dispatch('loading', false);
                console.error(error);
            },
            handleErrorSaveFile: function (error) {
                this.errorMessage = "Ошибка при сохранении файла. Попробуйте еще раз.";
                console.error(error);
            },
            formFieldDataChanged(e) {
                if(e.dataField == 'rollingStockTypeId') {

                    if(this.formData.specification != 'spcTrailer') {
                        if(e.value) {
                            this.specification = 'spcCar';
                            this.formData.specification = 'spcCar';

                        } else if(e.value == null) {
                            this.specification = 'spcTractor';
                            this.formData.specification = 'spcTractor';
                        }
                    }

                    window.rollingStockTypeId = e.value;
                    this.formData.rollingStockUnitTypeId = null;

                    if(e.value) {
                        this.storeRollingStockUnitTypes = new CustomStore({
                            key: 'rollingStockUnitTypeId',
                            byKey: function () {
                                //console.log(key);
                            },
                            load: (loadOptions) => {
                                let searchValue = loadOptions.searchValue != null ? loadOptions.searchValue : '';

                                let filter = searchValue != '' ? `,{"rollingStockUnitTypeShortName":{"op":"con","value":"${searchValue}"}}` : '';

                                return httpClient.get(`v2/Catalog/RollingStockUnitTypes?filter={"rollingStockTypeId":{"op":"eq","value":"${window.rollingStockTypeId}"}}${filter}`)
                                    .then((data) => {
                                        return {
                                            key: 'rollingStockUnitTypeId',
                                            data: data.data.items,
                                            totalCount: data.totals,
                                            summary: data.totals,
                                            groupCount: data.count,
                                        };
                                    })
                                    .catch(() => {
                                        throw 'Data Loading Error';
                                    });
                            }
                        })
                    } else {
                        this.storeRollingStockUnitTypes = [];
                    }
                }
            },
            displayExprRollingStockUnitTypes(data) {
                return data ? data.rollingStockUnitTypeShortName : '';
            },
            saveBase64(file, carId, fileKind, tokenId) {
                const reader = new FileReader();
                reader.onloadend = () => {
                    // use a regex to remove data url part
                    const base64String = reader.result
                        .replace("data:", "")
                        .replace(/^.+,/, "");

                    this.uploadFile(base64String, file.name, carId, fileKind, tokenId);
                };
                reader.readAsDataURL(file);
            },
            uploadFile(base64String, fileName, carId, fileKind, tokenId) {
                fileName = this.$options.filters.encodeURL(fileName);

                let url = `v2/Carrier/Cars/${carId}/Files/Upload?fileName=${fileName}&fileKind=${fileKind}&tokenId=${tokenId}`;

                return httpClient
                    .post(url, `"${base64String}"`,
                        {
                            headers: {
                                'Content-Type': 'application/json'
                            }
                        })
                    .then(() => { // response
                        this.countSaveFiles = this.countSaveFiles-1;

                        if(this.countSaveFiles <= 0) {
                            this.$router.push({name: 'CarrierCars'});
                        }
                    }).catch(this.handleErrorSaveFile);
            },
            validateVin(vin) {
                if (!vin.match("^([0-9a-hj-npr-zA-HJ-NPR-Z]{10,17})+$")) { return false;}
                var letters = [{ k: "A", v: 1 }, { k: "B", v: 2 }, { k: "C", v: 3 },
                    { k: "D", v: 4 }, { k: "E", v: 5 }, { k: "F", v: 6 }, { k: "G", v: 7 },
                    { k: "H", v: 8 }, { k: "J", v: 1 }, { k: "K", v: 2 }, { k: "L", v: 3 },
                    { k: "M", v: 4 }, { k: "N", v: 5 }, { k: "P", v: 7 }, { k: "R", v: 9 },
                    { k: "S", v: 2 }, { k: "T", v: 3 }, { k: "U", v: 4 }, { k: "V", v: 5 },
                    { k: "W", v: 6 }, { k: "X", v: 7 }, { k: "Y", v: 8 }, { k: "Z", v: 9 }];
                var weights = [8, 7, 6, 5, 4, 3, 2, 10, 0, 9, 8, 7, 6, 5, 4, 3, 2];
                var exclude = ["I", "O", "Q"];
                var val = 0;
                for (var idx = 0; idx < vin.length; idx++) {
                    var item = vin.charAt(idx).toUpperCase();
                    if (exclude.includes(function (x) { return x == item; })) { return false; }
                    var pos = (item.match("^[0-9]+$") != null) ? parseInt(item) : letters.filter(function (letter) { return letter.k == item; })[0].v;
                    val += (pos * weights[idx]);
                }
                var checksum = (val % 11);
                return (vin.charAt(8) == (checksum < 10 ? checksum.toString() : "X"));
            },
            carFileValueChanged(args) {
                if(args.value[0]) {
                    this.formData.carFile = args.value;
                    const file = args.value[0];
                    const fileReader = new FileReader();
                    fileReader.onload = () => {
                        this.carFileImageSource = fileReader.result;
                    };
                    fileReader.readAsDataURL(file);
                }
            },
            techicalPassportFileFrontValueChanged(args) {
                if(args.value[0]) {
                    this.formData.techicalPassportFileFront = args.value;
                    const file = args.value[0];
                    const fileReader = new FileReader();
                    fileReader.onload = () => {
                        this.techicalPassportFileFrontImageSource = fileReader.result;
                    };
                    fileReader.readAsDataURL(file);
                }
            },
            techicalPassportFileBackValueChanged(args) {
                if(args.value[0]) {
                    this.formData.techicalPassportFileBack = args.value;
                    const file = args.value[0];
                    const fileReader = new FileReader();
                    fileReader.onload = () => {
                        this.techicalPassportFileBackImageSource = fileReader.result;
                    };
                    fileReader.readAsDataURL(file);
                }
            },
            techInspectionFileValueChanged(args) {
                if(args.value[0]) {
                    this.formData.techInspectionFile = args.value;
                    const file = args.value[0];
                    const fileReader = new FileReader();
                    fileReader.onload = () => {
                        this.techInspectionFileImageSource = fileReader.result;
                    };
                    fileReader.readAsDataURL(file);
                }
            },
            insuranceFileValueChanged(args) {
                if(args.value[0]) {
                    this.formData.insuranceFile = args.value;
                    const file = args.value[0];
                    const fileReader = new FileReader();
                    fileReader.onload = () => {
                        this.insuranceFileImageSource = fileReader.result;
                    };
                    fileReader.readAsDataURL(file);
                }
            },
            onDelFile(name) {
                switch (name) {
                    case 'carFile':
                        this.formData.carFile = null;
                        this.carFileImageSource = '';
                        break;
                    case 'techicalPassportFileFront':
                        this.formData.techicalPassportFileFront = null;
                        this.techicalPassportFileFrontImageSource = '';
                        break;
                    case 'techicalPassportFileBack':
                        this.formData.techicalPassportFileBack = null;
                        this.techicalPassportFileBackImageSource = '';
                        break;
                    case 'techInspectionFile':
                        this.formData.techInspectionFile = null;
                        this.techInspectionFileImageSource = '';
                        break;
                    case 'insuranceFile':
                        this.formData.insuranceFile = null;
                        this.insuranceFileImageSource = '';
                        break;
                }
            },
            validateRollingStockUnitType(e) {
                if(this.formData.specification == 'spcTrailer' || this.formData.specification == 'spcCar') {
                    return e.value == null ? false : true;
                }

                return true;
            },
            handleBrandValueChange() { // e
                //this.formData.model = '';
            }
        },
        computed: {
            ...mapGetters([
                'isLoading',
                'Agent'
            ])
        },
    }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
    .tooltip-lable {
        display: block;
        position: absolute;
        padding-top: 5px;
        margin-left: 20px;
    }
</style>
