export default {
    defaultsoutes: [
        {
            id: 1,
            name: 'CarrierCars',
            title: 'Транспорт',
            hash: ''
        },
        {
            id: 2,
            name: 'CarrierDrivers',
            title: 'Водители',
            hash: ''
        }
    ]
}


